<template>
  <div id="contentMain" class="main-wrap" v-webTitle :data-title="textTitle">
    <!-- 通用顶部 -->
    <generalTop></generalTop>

    <!-- 中间区域 -->
    <div class="center-wrap">
      <!-- 面包屑区 -->
      <div class="breadcrumb-wrap">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ breadcrumbName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <!-- 列表区 -->
      <div class="list-content-wrap">
        <div
          class="list-item"
          v-for="item in listData"
          :key="item.id"
          @click="gotoDetail(item.id)"
          @mouseover="handleBgVisiable(item.id)"
          @mouseleave="handleBgUnVisiable(item.id)"
        >
          <div class="list-info">
            <span class="title">{{ item.title }}</span>
            <span class="intro">{{ item.introduce }}</span>
            <div class="author">
              <span>{{ $moment(item.createTime).format("YYYY-MM-DD") }}</span>
              <!-- <span>|</span>
              <span>发布：{{ item.createBy }}</span> -->
            </div>
          </div>
          <div class="list-img">
            <img
              src="../../assets/images/non-img.png"
              v-if="item.coverImg == null"
            />
            <img :src="serverURL + item.coverImg" alt="" v-else />
          </div>
          <div class="list-bg" :id="item.id" style="visibility: hidden">
            <img src="../../assets/images/list-bg.png" alt="" />
          </div>
        </div>
      </div>

      <!-- 分页 -->
      <div class="pagination-wrap">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="param.pageSize"
          layout="prev, pager, next"
          :total="totalCount"
        ></el-pagination>
      </div>
    </div>

    <!-- 底部区 -->
    <footbar></footbar>

    <!-- 侧边栏 -->
    <sidebar></sidebar>
  </div>
</template>

<script>
import generalTop from "../../components/GeneralTop/index";
import footbar from "../../components/Footbar/index";
import sidebar from "../../components/Sidebar/index";
import { getListData, getDetail } from "@/api/common";
import global from "@/utils/global.js";

export default {
  components: {
    generalTop,
    footbar,
    sidebar,
  },

  data() {
    return {
      // 页面参数
      textTitle: "", //页面标题
      breadcrumbName: "", //面包屑标题
      path: "", //面包屑路径

      // 服务器路径
      serverURL: global.httpUrl,

      // 监听页面参数
      contentType: "",

      // 列表数据
      listData: [],

      // 总条数
      totalCount: 0,

      // 当前页
      currentPage: 1,

      // 接口参数
      param: {
        columnId: null,
        pageNum: null,
        pageSize: 6,
      },
    };
  },

  watch: {
    //监听路由地址的改变
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.type) {
          this.contentType = this.$route.query.type;
        }
        this.handleContent(this.contentType);
      },
    },
  },

  created() {
    // chrome
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
    // safari
    window.pageYOffset = 0;
  },

  methods: {
    // 处理参数
    handleContent(contentType) {
      switch (contentType) {
        case "qyxw":
          this.textTitle = "企业新闻";
          this.breadcrumbName = "企业新闻";
          this.param.columnId = 2;
          this.getData();
          break;
        case "hydt":
          this.textTitle = "行业动态";
          this.breadcrumbName = "行业动态";
          this.param.columnId = 3;
          this.getData();
          break;
        case "fwal":
          this.textTitle = "服务案例";
          this.breadcrumbName = "服务案例";
          this.param.columnId = 4;
          this.getData();
          break;
        case "hdzx":
          this.textTitle = "活动中心";
          this.breadcrumbName = "活动中心";
          this.param.columnId = 5;
          this.getData();
          break;
        case "qydj":
          this.textTitle = "党务建设";
          this.breadcrumbName = "党务建设";
          this.param.columnId = 26;
          this.getData();
          break;
      }
    },

    // 获取列表数据
    getData() {
      this.param.pageNum = this.currentPage;

      getListData(this.param).then((response) => {
        this.totalCount = response.total;
        this.listData = response.data;
      });
    },

    // 跳转到详情页
    gotoDetail(id) {
      getDetail(id).then((res) => {
        this.$router.push({
          path: "/Common/detail",
          query: { id, type: this.contentType, content: res.data },
        });
      });
    },

    // 分页
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getData();

      contentMain.scrollIntoView();
    },

    // 控制列表背景图可见性
    handleBgVisiable(id) {
      document.getElementById(id).setAttribute("style", "visibility:unset;");
    },
    handleBgUnVisiable(id) {
      document.getElementById(id).setAttribute("style", "visibility:hidden;");
    },
  },
};
</script>

<style scoped>
.list-content-wrap {
  width: 100%;
  padding: 20px;
}

.list-item {
  position: relative;
  margin-bottom: 33px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.list-info {
  display: flex;
  flex-direction: column;
  width: 809px;
  height: 181px;
}

.list-info .title {
  font-size: 24px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #292929;
}

.list-info .intro {
  margin-top: 17px;
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #292929;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.list-info .author {
  margin-top: auto;
  width: 236px;
  display: flex;
  justify-content: space-between;
}

.list-info .author span {
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #9e9e9e;
}

.list-img {
  width: 253px;
  height: 181px;
  border-radius: 10px;
  overflow: hidden;
}

.list-img img {
  width: 100%;
  height: 100%;
}

.list-bg {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
