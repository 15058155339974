<template>
  <div class="main-wrap" v-webTitle :data-title="textTitle">
    <!-- 通用顶部 -->
    <generalTop></generalTop>

    <!-- 中间内容区 -->
    <div class="center-wrap">
      <!-- 面包屑区 -->
      <div class="breadcrumb-wrap">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path }">{{
            breadcrumbName
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ breadcrumbName_end }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <!-- 文章区 -->
      <div class="article-wrap">
        <div class="title-wrap">
          <div class="title">
            <span>{{ contentTitle }}</span>
          </div>
          <div class="other">
            <span class="time">{{ createTime }}</span>
            <!-- <span>|</span>
            <span class="author">发布：{{ createBy }}</span> -->
          </div>
        </div>

        <div class="article-content-wrap">
          <div v-html="doc"></div>
        </div>

        <div
          class="btn-wrap"
          v-show="this.contentType === 'hdzx'"
          @click="gotoSign"
        >
          我要报名 (已有{{ perCount }}人报名)
        </div>
      </div>
    </div>

    <!-- 活动报名蒙版 -->
    <div class="edit-info" v-show="showJoin">
      <div class="edit-info-exit">
        <i class="iconfont icon-cha" @click="exit"></i>
      </div>

      <div class="edit-box">
        <span>活动报名</span>

        <div class="name">
          <span>姓名:</span>
          <el-input v-model="name"></el-input>
        </div>

        <div class="phone">
          <span>电话:</span>
          <el-input v-model="phone"></el-input>
        </div>
      </div>

      <div class="submit-btn" @click="handleJoin">提交</div>
    </div>

    <!-- 底部区 -->
    <footbar></footbar>

    <!-- 侧边栏 -->
    <sidebar></sidebar>
  </div>
</template>

<script>
import generalTop from "../../components/GeneralTop/index";
import footbar from "../../components/Footbar/index";
import sidebar from "../../components/Sidebar/index";
import { handleJoinActivity, getDetail } from "@/api/common";

export default {
  components: {
    generalTop,
    footbar,
    sidebar,
  },

  data() {
    return {
      // 页面参数
      textTitle: "", //页面标题
      breadcrumbName: "", //面包屑标题
      path: "", //面包屑路径
      breadcrumbName_end: "详情页", //面包屑最后一项标题
      contentTitle: "", //内容标题
      createTime: "", //创建时间
      createBy: "", //作者
      doc: "", //内容

      // 页面内容类型
      contentType: "",

      // 控制报名窗口可见性
      showJoin: false,

      // 活动报名相关参数
      perCount: null, //活动报名总人数
      activityId: null,
      name: "",
      phone: "",
    };
  },

  mounted() {
    this.handleContent();
  },

  methods: {
    // 处理参数
    handleContent() {
      this.contentType = this.$route.query.type;
      let content = this.$route.query.content;
      this.activityId = this.$route.query.id;

      // console.log(contentType);
      // console.log(content);

      switch (this.contentType) {
        case "team":
          this.textTitle = "个人简介";
          this.breadcrumbName = "专业团队";
          this.breadcrumbName_end = "个人简介";
          this.path = "/Team/index";
          this.contentTitle = content.name;
          this.createTime = content.createTime;
          this.createBy = content.createBy;
          this.doc = content.introduce;
          break;
        case "qyxw":
          this.textTitle = "企业新闻";
          this.breadcrumbName = "企业新闻";
          this.path = "/Common/list?type=qyxw";
          this.contentTitle = content.title;
          this.createTime = content.createTime;
          this.createBy = content.createBy;
          this.doc = content.content;
          break;
        case "hydt":
          this.textTitle = "行业动态";
          this.breadcrumbName = "行业动态";
          this.path = "/Common/list?type=hydt";
          this.contentTitle = content.title;
          this.createTime = content.createTime;
          this.createBy = content.createBy;
          this.doc = content.content;
          break;
        case "fwal":
          this.textTitle = "服务案例";
          this.breadcrumbName = "服务案例";
          this.path = "/Common/list?type=fwal";
          this.contentTitle = content.title;
          this.createTime = content.createTime;
          this.createBy = content.createBy;
          this.doc = content.content;
          break;
        case "hdzx":
          this.textTitle = "活动中心";
          this.breadcrumbName = "活动中心";
          this.path = "/Common/list?type=hdzx";
          this.contentTitle = content.title;
          this.createTime = content.createTime;
          this.createBy = content.createBy;
          this.doc = content.content;
          this.handelGetDetail();
          break;
        case "qydj":
          this.textTitle = "党务建设";
          this.breadcrumbName = "党务建设";
          this.path = "/Common/list?type=qydj";
          this.contentTitle = content.title;
          this.createTime = content.createTime;
          this.createBy = content.createBy;
          this.doc = content.content;
          break;
      }
    },

    // 获取报名人数
    handelGetDetail() {
      getDetail(this.activityId).then((res) => {
        // console.log(res);
        this.perCount = res.data.perCount;
      });
    },

    // 报名按钮
    gotoSign() {
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;

      this.showJoin = true;
    },

    // 退出报名窗口
    exit() {
      this.showJoin = false;
    },

    // 提交报名信息
    handleJoin() {
      let data = {
        activityId: this.activityId,
        name: this.name,
        phone: this.phone,
      };

      handleJoinActivity(data).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "活动报名成功",
            type: "success",
          });
          this.showJoin = false;
          this.handelGetDetail();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.article-wrap {
  padding-top: 20px;
}

.title {
  width: 480px;
}

.title span {
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.other {
  margin-top: 20px;
}

.other span:nth-child(2) {
  margin: 0 20px;
}

.other span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #595959;
}

.article-content-wrap {
  margin-top: 40px;
}

.article-content span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 3;
}

.content-img {
  display: flex;
  justify-content: center;
}

.btn-wrap {
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  width: 230px;
  height: 43px;
  background: #ff7800;
  border-radius: 10px;
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  cursor: pointer;
}

.edit-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  width: 991px;
  height: 671px;
  background: rgba(56, 56, 56, 0.7);
  z-index: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-info .edit-info-exit {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.edit-info .edit-info-exit .iconfont {
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

.edit-info .edit-box {
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-info .edit-box > span {
  margin-bottom: 50px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fdfdfd;
}

.name,
.phone {
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.name span,
.phone span {
  display: block;
  width: 30%;
  font-size: 29px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #fdfdfd;
  text-align: right;
}

.name .el-input,
.phone .el-input {
  margin-left: 20px;
  width: 70%;
}

.name ::v-deep .el-input__inner,
.phone ::v-deep .el-input__inner {
  border: none;
  border-bottom: 2px solid #fdfdfd;
  border-radius: 0;
  background-color: transparent;
  color: #fdfdfd;
  font-size: 29px;
}

.submit-btn {
  width: 118px;
  height: 53px;
  background: #ff8516;
  border-radius: 10px;
  font-size: 24px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
